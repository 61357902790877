import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import 'react-widgets/styles.css';
import { isNotEmptyArray } from 'src/services/validationService';
import { getReportBudgetStatusCreditExecutionGeneratedListData, getReportBudgetStatusCreditExecutionGeneratedListIsFetching, getReportBudgetStatusCreditExecutionGeneratedIsFetching } from 'src/redux/reports/reportsReducer'
import { tryGetReportBudgetStatusCreditExecutionList } from 'src/redux/reports/reportsActionCreactor'
import { clearListReportBudgetGeneratedList } from 'src/redux/reports/reportsActions'
import GeneratedReportsTable from "./GeneratedReportsTable";

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';


let intervalID;

const RecentlyGeneratedReports = ({ selectedReport }) => {

    const dispatch = useDispatch();

    const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

    const subcodeDetailData = useSelector(state => getReportBudgetStatusCreditExecutionGeneratedListData(state));
    const tableData = subcodeDetailData?.data?.slice(0, 10);
    const hasSubcodeDetailData = isNotEmptyArray(subcodeDetailData?.data);
    const isFeching = useSelector(state => getReportBudgetStatusCreditExecutionGeneratedListIsFetching(state));
    const reportIsFeching = useSelector(state => getReportBudgetStatusCreditExecutionGeneratedIsFetching(state));

     const defaultParams = {
        page: 1,
        size: 10,
        period_id: globalSelectedPeriod?.id
    };

    const loadData = () => {
        if(selectedReport == "recently"){
        dispatch(clearListReportBudgetGeneratedList());
        dispatch(tryGetReportBudgetStatusCreditExecutionList(defaultParams));
        }   
    };

    useEffect(() => {
        if(selectedReport != "recently"){
            clearInterval(intervalID);
        } else {
            clearInterval(intervalID);
            loadData();
            intervalID = setInterval( () => {
                let URLactual = window.location.pathname
                if (URLactual === "/reportes/ejecucion-presupuesto/generados") {
                    loadData();
                }else{
                    clearInterval(intervalID);
                }
             }, 10000);
        }
    }, [selectedReport]);

    return <>
        <div className='table-responsive'>
            <GeneratedReportsTable 
                hasSubcodeDetailData={hasSubcodeDetailData}
                subcodeDetailData={tableData}
                isFeching={isFeching}
                reportIsFeching={reportIsFeching}
                loadData={loadData}
            />
        </div>
    </>
};

export default RecentlyGeneratedReports;